body {
  margin: 0;
  background-color: #fafbff;
}

body * {
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Styles for h1 */
h1 {
  font-size: 2rem; /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 0.5rem; /* Adjust spacing as needed */
}

/* Styles for h2 */
h2 {
  font-size: 1.5rem; /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 0.5rem; /* Adjust spacing as needed */
}

/* Styles for h3 */
h3 {
  font-size: 1.25rem; /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 0.5rem; /* Adjust spacing as needed */
}
